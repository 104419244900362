<script>
import { http } from "../../http";

export default {
  data() {
    return {
      allPushBatches: null,
      clients: [],
      filterClient: null,
      currentPage: 1,
    };
  },
  mounted() {
    this.getClients();
  },
  methods: {
    getAllPushBatches() {
      var payload = {
        client_id: this.filterClient,
      };

      http
        .post("painel/atend_comercial/report_push_batchs/", payload)
        .then((response) => (this.allPushBatches = response.data));
    },

    onChangeFilterClient() {
      this.allPushBatches = null;

      this.filterClient = document.getElementById("selectFilterClient").value;

      if (this.filterClient) {
        this.getAllPushBatches();
      }
    },

    getClients: function () {
      var payload = {
        current_page: this.currentPage,
      };
      http
        .post("read_client/", payload)
        .then((res) => (this.clients = res.data));
    },

    downloadFile: function (fileUrl) {
      const link = document.createElement("a");
      link.href = fileUrl;
      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    },

    goToPage: function (id) {
      this.$router.push("/push-batch/" + id);
    },
  },
};
</script>

<template>
  <div class="container-fluid">
    <h1 class="h3 mb-2 text-gray-800">Informações dos Push Batches</h1>

    <div class="card shadow mb-4">
      <div class="card-header py-3">
        <h6 class="m-0 font-weight-bold text-primary">
          Informações dos Push Batches por Cliente
        </h6>
        <div class="d-flex align-items-center justify-content-end">
          <label class="list-clients" for="selectFilterClient"
            ><b>SELECIONE UM CLIENTE: </b></label
          >
          <select
            class="custom-select col-2 btn-header"
            id="selectFilterClient"
            @change="onChangeFilterClient"
          >
            <option value="" selected>-- CLIENTES --</option>
            <option
              v-for="client in clients"
              :value="client.id"
              :key="client.id"
            >
              {{ client.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table
            class="table table-bordered"
            id="dataTable"
            width="100%"
            cellspacing="0"
            selectedClass="table-info"
          >
            <thead>
              <tr>
                <th>Nome</th>
                <th>Tipo</th>
                <th>Sender</th>
                <th>Message Template</th>
                <th>Criado por</th>
                <th>Total de Números</th>
                <th>Linhas Processados</th>
                <th>Quantidade de Falha</th>
                <th>Arquivo</th>
                <th>Visualizar</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="data in allPushBatches" v-bind:key="data.id">
                <td>
                  <span class="link-action" @click="goToPage(data.id)">{{
                    data.identifier
                  }}</span>
                </td>
                <td>{{ data.push_type }}</td>
                <td>{{ data.sender_phone_number }}</td>
                <td>{{ data.message_template_name }}</td>
                <td>{{ data.created_by_email }}</td>
                <td>{{ data.count_lines }}</td>
                <td>{{ data.processed }}</td>
                <td>{{ data.failed }}</td>
                <td>
                  <div class="fs-4 mb-3">
                    <i
                      v-if="data.file_url != null"
                      class="bi bi-filetype-csv text-primary button-action"
                      @click="downloadFile(data.file_url)"
                    ></i>
                    <i v-else class="bi bi-x-circle-fill text-danger"></i>
                  </div>
                </td>
                <td>
                  <div class="fs-4 mb-3">
                    <i
                      class="bi bi-eye text-primary button-action"
                      @click="goToPage(data.id)"
                    ></i>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="!this.filterClient" class="container-loading">
            <i class="bi bi-exclamation-triangle-fill">
              SELECIONE UM CLIENTE
            </i>
          </div>
          <div
            v-else-if="!this.allPushBatches && this.filterClient"
            class="container-loading"
          >
            <i class="bi bi-hourglass-split">Carregando...</i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.container-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-header {
  margin: 0 10px;
}
.button-action {
  cursor: pointer;
}
.link-action {
  cursor: pointer;
  color: #007bff;
  text-decoration: underline;
}
.list-clients {
  margin-top: 10px;
}
</style>
