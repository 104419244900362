<script>
import { http } from "../../http";
import MessageCard from "../shared/message-cards/MessageCard.vue";

export default {
  components: {
    "message-card": MessageCard,
  },
  data() {
    return {
      allMessages: null,
      selectedRange: null,
      startDate: null,
      endDate: null,
      count_messages: {
        total_messages: 0,
        total_messages_delivered: 0,
        total_messages_failed: 0,
        total_messages_queued: 0,
      },
    };
  },
  mounted() {
    this.getAllMessages();
  },
  methods: {
    getAllMessages() {
      var payload = {
        start_date: this.startDate,
        end_date: this.endDate,
      };

      http
        .post("painel/atend_comercial/report_by_messaging_status/", payload)
        .then((response) => (this.allMessages = response.data))
        .finally(() => {
          this.calculateTotalMessages();
        });
    },

    calculateTotalMessages() {
      this.count_messages.total_messages = 0;
      this.count_messages.total_messages_delivered = 0;
      this.count_messages.total_messages_failed = 0;
      this.count_messages.total_messages_queued = 0;

      this.allMessages.forEach((message) => {
        this.count_messages.total_messages += message.total_messages;
        this.count_messages.total_messages_delivered +=
          message.total_messages_delivered;
        this.count_messages.total_messages_failed +=
          message.total_messages_failed;
        this.count_messages.total_messages_queued +=
          message.total_messages_queued;
      });
    },

    applyFilters() {
      this.allMessages = null;
      this.count_messages = {
        total_messages: 0,
        total_messages_delivered: 0,
        total_messages_failed: 0,
        total_messages_queued: 0,
      };

      if (this.selectedRange) {
        this.startDate = `${this.selectedRange[0].getDate()}/${
          this.selectedRange[0].getMonth() + 1
        }/${this.selectedRange[0].getFullYear()}`;

        if (this.selectedRange[1]) {
          this.endDate = `${this.selectedRange[1].getDate()}/${
            this.selectedRange[1].getMonth() + 1
          }/${this.selectedRange[1].getFullYear()}`;
        } else {
          this.endDate = this.startDate;
        }
      } else {
        this.startDate = null;
        this.endDate = null;
      }

      this.getAllMessages();
    },
  },
};
</script>

<template>
  <div class="container-fluid">
    <h1 class="h3 mb-2 text-gray-800">Informações das Mensagens</h1>

    <div class="row">
      <message-card
        :nameCard="'Total de Mensagens'"
        :amount="count_messages.total_messages"
        :color="'info'"
      ></message-card>

      <message-card
        :nameCard="'Mensagens Entregues'"
        :amount="count_messages.total_messages_delivered"
        :color="'success'"
      ></message-card>

      <message-card
        :nameCard="'Mensagens na Fila'"
        :amount="count_messages.total_messages_queued"
        :color="'warning'"
      ></message-card>

      <message-card
        :nameCard="'Mensagens com Falha'"
        :amount="count_messages.total_messages_failed"
        :color="'danger'"
      ></message-card>
    </div>

    <div class="card shadow mb-4">
      <div class="card-header py-3">
        <h6 class="m-0 font-weight-bold text-primary">
          Informações das Mensagens por Cliente
        </h6>
        <div class="d-flex align-items-center justify-content-end">
          <div class="col-3 d-inline-block">
            <VueDatePicker
              v-model="selectedRange"
              range
              :enable-time-picker="false"
              format="dd/MM/yyyy"
              select-text="Selecionar"
              cancel-text="Cancelar"
            />
          </div>

          <button
            type="button"
            class="btn btn-outline-primary ml-2"
            title="Filtrar"
            @click="applyFilters"
          >
            Aplicar Filtro
          </button>
        </div>
      </div>

      <div class="card-body">
        <div class="table-responsive">
          <table
            class="table table-bordered"
            id="dataTable"
            width="100%"
            cellspacing="0"
            selectedClass="table-info"
          >
            <thead>
              <tr>
                <th>ID do Cliente</th>
                <th>Nome do Cliente</th>
                <th>Tipo do Curso</th>
                <th>ID do Curso</th>
                <th>Nome do Curso</th>
                <th>Total de Enrollments</th>
                <th>Enviadas</th>
                <th>Entregues</th>
                <th>Lidas</th>
                <th>Na Fila</th>
                <th>Com Falha</th>
                <th>Reenviadas</th>
                <th>TOTAL</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="data in allMessages" v-bind:key="data.id">
                <td>{{ data.enrollment__course__company__client__id }}</td>
                <td>{{ data.enrollment__course__company__client__name }}</td>
                <td>
                  {{
                    data.enrollment__course__flow_execution == "original"
                      ? "Alya"
                      : data.enrollment__course__flow_execution == "chatbots"
                      ? "Lumy"
                      : data.enrollment__course__flow_execution
                  }}
                </td>
                <td>{{ data.enrollment__course__id }}</td>
                <td>{{ data.enrollment__course__name }}</td>
                <td>{{ data.total_enrollments }}</td>
                <td>{{ data.total_messages_sent }}</td>
                <td>{{ data.total_messages_delivered }}</td>
                <td>{{ data.total_messages_read }}</td>
                <td>{{ data.total_messages_queued }}</td>
                <td>{{ data.total_messages_failed }}</td>
                <td>{{ data.total_messages_resent }}</td>
                <td>{{ data.total_messages }}</td>
              </tr>
            </tbody>
          </table>
          <div v-if="!this.allMessages" class="container-loading">
            <i class="bi bi-hourglass-split">Carregando...</i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.container-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-header {
  margin: 0 10px;
}
</style>
