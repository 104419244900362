<script>
import { http } from "../../http";

export default {
  data() {
    return {
      allEnrollments: null,
      selectedRange: null,
      startDate: null,
      endDate: null,
      filterStatus: null,

      clients: [],
      filterClient: null,
      currentPage: 1,
    };
  },
  mounted() {
    // this.getAllEnrollments();
    // this.getClients();
    this.getPushBatch();

    console.log("clients: " + this.clients);
  },
  methods: {
    getAllEnrollments() {
      var payload = {
        course_status: this.filterStatus,
        course_type: this.filterType,
        start_date: this.startDate,
        end_date: this.endDate,
      };

      http
        .post("enrollments-information/", payload)
        .then((response) => (this.allEnrollments = response.data));
    },

    onChangeFilterClient() {
      this.filterClient = document.getElementById("selectFilterClient").value;
    },

    onChangeFilterStatus() {
      this.filterStatus = document.getElementById("selectFilterStatus").value;
    },

    applyFilters() {
      console.log("TESTE2: " + this.filterClient);
      this.allEnrollments = null;

      if (this.selectedRange) {
        this.startDate = `${this.selectedRange[0].getDate()}/${
          this.selectedRange[0].getMonth() + 1
        }/${this.selectedRange[0].getFullYear()}`;

        if (this.selectedRange[1]) {
          this.endDate = `${this.selectedRange[1].getDate()}/${
            this.selectedRange[1].getMonth() + 1
          }/${this.selectedRange[1].getFullYear()}`;
        } else {
          this.endDate = this.startDate;
        }
      } else {
        this.startDate = null;
        this.endDate = null;
      }

      this.getAllEnrollments();
    },

    getClients: function () {
      var payload = {
        current_page: this.currentPage,
      };
      http
        .post("read_client/", payload)
        .then((res) => (this.clients = res.data));
    },

    downloadFile: function (fileUrl) {
      fileUrl =
        "https://api002.backblazeb2.com/b2api/v2/b2_download_file_by_id?fileId=4_z67d6182cd884391f7d530717_f105e7b581957c64f_d20240401_m212755_c002_v0001147_t0037_u01712006875169";

      const link = document.createElement("a");
      link.href = fileUrl;
      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    },

    goToPage: function (page) {
      console.log("PAGE: " + page);
    },

    getPushBatch: function () {
      const pushBatchId = this.$route.params.id;
      console.log("PAGE: " + pushBatchId);
    },
  },
};
</script>

<template>
  <div class="container-fluid">
    <h1 class="h3 mb-2 text-gray-800">Informações dos Push Batches</h1>

    <div class="card shadow mb-4">
      <div class="card-header py-3">
        <h6 class="m-0 font-weight-bold text-primary">
          Informações dos Push Batches por Cliente
        </h6>
        <div class="d-flex align-items-center justify-content-end">
          <select
            class="custom-select col-2 btn-header"
            id="selectFilterClient"
            @change="onChangeFilterClient"
          >
            <option value="" selected>TODOS OS CLIENTES</option>
            <option
              v-for="client in clients"
              :value="client.id"
              :key="client.id"
            >
              {{ client.name }}
            </option>
          </select>

          <select
            class="custom-select col-2 btn-header"
            id="selectFilterStatus"
            @change="onChangeFilterStatus"
          >
            <option value="" selected>STATUS DO CURSO</option>
            <option value="active">Ativo</option>
            <option value="inactive">Inativo</option>
          </select>

          <div class="col-3 d-inline-block">
            <VueDatePicker
              v-model="selectedRange"
              range
              :enable-time-picker="false"
              format="dd/MM/yyyy"
              select-text="Selecionar"
              cancel-text="Cancelar"
            />
          </div>

          <button
            type="button"
            class="btn btn-outline-primary ml-2"
            title="Filtrar"
            @click="applyFilters"
          >
            Aplicar Filtro
          </button>
        </div>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table
            class="table table-bordered"
            id="dataTable"
            width="100%"
            cellspacing="0"
            selectedClass="table-info"
          >
            <thead>
              <tr>
                <th>Nome</th>
                <th>Tipo</th>
                <th>Status</th>
                <th>Total de Números</th>
                <!-- <th>Projeto</th> -->
                <th>Linhas Processados</th>
                <th>Linhas Enviadas</th>
                <th>Quantidade de Falha</th>
                <th>Progresso</th>
                <th>Criado por</th>
                <th>Data de Criação</th>
                <th>Registration Page</th>
                <th>Arquivo</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="data in allEnrollments" v-bind:key="data.id">
                <td>#{{ data.id }}</td>
                <td>
                  <span class="link-action" @click="goToPage(data.name)">{{
                    data.name
                  }}</span>
                </td>
                <td>
                  {{
                    data.flow_execution == "original"
                      ? "Alya"
                      : data.flow_execution == "chatbots"
                      ? "Lumy"
                      : data.flow_execution
                  }}
                </td>
                <td>
                  <i
                    v-if="data.is_active"
                    class="bi bi-check-circle-fill text-success"
                  ></i>
                  <i v-else class="bi bi-x-circle-fill text-danger"></i>
                </td>
                <!-- <td>{{ data.project_name }}</td> -->
                <td>{{ data.active_enrollments }}</td>
                <td>{{ data.completed_enrollments }}</td>
                <td>{{ data.all_enrollments }}</td>
                <td>
                  <div class="fs-4 mb-3">
                    <i
                      class="bi bi-filetype-csv text-primary button-action"
                      @click="downloadFile(data.all_enrollments)"
                    ></i>
                  </div>
                </td>
                <td>
                  <div class="fs-4 mb-3">
                    <i
                      class="bi bi-eye text-primary button-action"
                      @click="goToPage(data.all_enrollments)"
                    ></i>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="!this.allEnrollments" class="container-loading">
            <i class="bi bi-hourglass-split">Carregando...</i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.container-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-header {
  margin: 0 10px;
}
.button-action {
  cursor: pointer;
}
.link-action {
  cursor: pointer;
  color: #007bff;
  text-decoration: underline;
}
</style>
